<template>
<div>
 <div>
     <header class="page-header">
      <el-row :gutter="20" align="middle">
        <el-col :span="12">
          <h2> Tag жагсаалт </h2>
        </el-col>
        <el-col :span="12">
          <div class="actions text-right">
            <router-link to="/add-tag">
              <el-button type="success" class="item">Нэмэх</el-button>
            </router-link>
          </div>
        </el-col>
      </el-row>
    </header>
    <div class="panel tabled">
        <el-tabs v-model="activeTabStatus">
            <el-tab-pane label="Бүгд" name="all">
            </el-tab-pane>
        </el-tabs>
        <div class="pl20 pr20 table-filter mb10">
          <el-row :gutter="10" align="middle" type="flex">
          <el-col :span="12">
            <el-input placeholder="Хайх" class="input-with-select" v-model="search" @input="onSearch">
              <el-button slot="prepend" icon="el-icon-search"></el-button>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-select v-model="type" placeholder="Төрөл сонгох" clearable @change="handleChange">
              <el-option
                v-for="(tag, index) in tagType"
                :key="index"
                :label="tag.label"
                :value="tag.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6" class="text-right">
          <el-dropdown @command="handleSort">
              <el-button type="default">{{sortName}} <i class="el-icon-sort"></i></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command = 'filter.value' v-for="(filter, indexFilter) in dropdownDataFilter" :key="indexFilter">{{filter.label}}</el-dropdown-item>
              </el-dropdown-menu>
          </el-dropdown>
      </el-col>
          </el-row>
        </div>
    <el-table
        :data="tagData"
        v-loading="isLoading"
        style="width: 100%"
        @cell-click="clickCell">
        <el-table-column label="Монгол нэр" prop="name_mon">
        </el-table-column>
        <el-table-column label="Англи нэр" prop="name_eng">
        </el-table-column>
        <el-table-column label="Дэвсгэр өнгө" prop="color">
          <template slot-scope="scope">
            <el-tag size="medium" :style="{backgroundColor: scope.row.color}">{{scope.row.name_mon}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Text өнгө" prop="color">
          <template slot-scope="scope">
            <el-tag size="medium" :style="{backgroundColor: scope.row.text_color}">{{scope.row.name_mon}}</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column label="Загвар" prop="design">
        </el-table-column> -->
    </el-table>
    <el-pagination
      @current-change = "curentPageHandler"
      @size-change = "sizeChangeHandler"
      class="text-right mt10"
      background
      :current-page.sync="currentPage"
      :page-size="pageSize"
      :page-sizes="[5, 20, 50, 100]"
      layout="total, sizes, prev, pager, next"
      :total="totalCount">
    </el-pagination>
    </div>
</div>
</div>
</template>
<script>
import services from '../../../helpers/services'
export default {
  data () {
    return {
      type: '',
      sortName: 'Эрэмбэлэх',
      sort: '',
      field: '',
      activeTabStatus: 'all',
      search: '',
      tagData: [],
      isLoading: false,
      pageSize: 20,
      pageFrom: 0,
      totalCount: 0,
      page: 1,
      currentPage: 1,
      dropdownDataFilter: [
        {
          label: 'Бүгд',
          value: 'all'
        }, {
          label: 'Нэр А - Я',
          value: 'firstChar'
        }, {
          label: 'Нэр Я - А',
          value: 'lastChar'
        }, {
          label: 'Шинэ эхэнд',
          value: 'newest'
        }, {
          label: 'Хуучин эхэнд',
          value: 'oldest'
        }
      ],
      tagType: [{
        value: 'category',
        label: 'Апп категори'
      }, {
        value: 'product',
        label: 'Бүтээгдэхүүн'
      }]
    }
  },
  created () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      const incomingSearch = this.$route.query.search
      const incomingField = this.$route.query.field
      const incomingSort = this.$route.query.sort
      const incomingType = this.$route.query.type
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      this.search = incomingSearch
      this.field = incomingField
      this.sort = incomingSort
      this.type = incomingType
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.getTag(from, size, this.search, this.field, this.sort, this.type)
  },

  methods: {
    handleChange () {
      this.getTag((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.field, this.sort, this.type)
    },
    onSearch () {
      this.$router.push({ name: 'tagList', query: { page: this.currentPage, size: this.pageSize, search: this.search, field: this.field, sort: this.sort, type: this.type } }).catch(() => {})
      this.getTag((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.field, this.sort, this.type)
    },

    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'tagList', query: { page: item, size: this.pageSize, search: this.search, field: this.field, sort: this.sort, type: this.type } }).catch(() => {})
      this.getTag((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.field, this.sort, this.type)
    },

    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'tagList', query: { page: item, size: this.pageSize, search: this.search, field: this.field, sort: this.sort, type: this.type } }).catch(() => {})
      this.getTag((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.field, this.sort, this.type)
    },

    handleSort (data) {
      const filters = {
        newest: {
          field: 'created_at',
          sort: 'desc',
          sortName: 'Шинэ эхэнд'
        },
        oldest: {
          field: 'created_at',
          sort: 'asc',
          sortName: 'Хуучин эхэнд'
        },
        firstChar: {
          field: 'name_mon',
          sort: 'asc',
          sortName: 'Нэр А - Я'
        },
        lastChar: {
          field: 'name_mon',
          sort: 'desc',
          sortName: 'Нэр Я - А'
        },
        all: {
          field: '',
          sort: '',
          sortName: 'Эрэмбэлэх'
        }
      }
      const currentFilter = JSON.parse(JSON.stringify(filters[data]))
      this.field = currentFilter.field
      this.sort = currentFilter.sort
      this.sortName = currentFilter.sortName
      this.$router.push({ name: 'tagList', query: { page: this.currentPage, size: this.pageSize, search: this.search, field: this.field, sort: this.sort, type: this.type } }).catch(() => {})
      this.getTag(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.field, this.sort, this.type)
    },

    getTag (from, size, search, field, sort, type) {
      const query = '?search_text=' + search + '&from=' + from + '&size=' + size + '&field=' + field + '&sort=' + sort + '&type=' + type
      this.isLoading = true
      services.getTag(query).then((response) => {
        this.tagData = response.datas
        this.totalCount = response.total
        this.isLoading = false
      })
    },

    clickCell (row, column, cell, event) {
      this.$router.push({
        name: 'updateTag',
        params: {
          id: row.id
        },
        query: { page: this.currentPage, size: this.pageSize, search: this.search, field: this.field, sort: this.sort, type: this.type }
      })
    }
  }
}
</script>
